@font-face {
	font-family: 'shirokuma';
	src: url('/font/001Shirokuma-Regular.otf');
}

html {
	width: 100%;
	height: 100%;
	// min-height: 100%;
	font-size: 62.5%;
}

body {
	position: relative;
	z-index: 0;
	width: 100%;
	height: 100%;
	font-family: $font-def2;
	font-size: 1.6rem;
	// font-weight: 500; // '游ゴシック'のみを設定時には必須
	background-color: #fff;
	background-size: cover;
	// overflow-x: hidden;
}

* {
	background-size: cover;
	// -webkit-appearance: none;
	line-height: 1.618;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: #951006;
	// text-shadow: 0 0 3px #0b0;
}