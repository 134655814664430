.h {
    &_header {
        // border-bottom: 3px solid #ccc;
        display: flex;
        justify-content: flex-start;
        @extend %cf;
    }
    &_title {
        // width: 60%;
        flex-grow: 2;
        flex-shrink: 1;
        display: inline-flex;
        align-items: center;
        img {
            width: 100%;
            max-width: 260px;
            height: auto;
            padding: 3px 0;
            vertical-align: bottom;
        }
    }
    &_menu {
        display: flex;
        align-items: center;
        width: 100%;
        &_ico {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: stretch;
            width: 20%;
            margin: 2% 0;
            padding: 0;
            text-align: center;
            color: #ff0078;
            text-decoration: none;
            &:not(:first-of-type) {
                border-left: 1px solid #FF9AAF;
            }
            svg {
                font-size: 3rem;
            }
            span {
                font-size: 1rem;
            }
        }
    }
}


.c {
    &_title {
        position: relative;
        padding: 15px;
        color: #fff;
        text-shadow: 0 3px 1px #444;
        font-size: 2rem;
        svg {
            margin-right: .5rem;
            filter: drop-shadow(0 3px 1px #444);
        }
        &.ichioshi {
            z-index: 10;
            width: 100%;
            padding: 0;
            &::before {
                content: '';
                display: block;
                width: 100%;
                padding-top: 16.67%;
            }
        }
        &_outer {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            pointer-events: none;
            img {
                width: 100%;
                height: auto;
                vertical-align: bottom;
            }
        }
        &.mens {
            @include background-image(linear-gradient(135deg, #29E9E2, #0EAEF0, #73C0F6));
            border-bottom: 3px solid #dfeef9;
        }
        &.girls {
            @include background-image(linear-gradient(135deg, #FF759C, #fd4a5f, #ff947b));
            border-bottom: 3px solid #F9DFF0;
        }
        &.reco {
            @include background-image(linear-gradient(135deg, #6bf17b, #43d29b, #21d4e6));
            border-bottom: 3px solid #D4FEE3;
        }
     }
    &_list {
        // border-top: 3px solid #ccc;
        list-style: none;
        &_outer {
            display: flex;
            padding-left: 2%;
            text-decoration: none;
            color: #bd9577;
            .disc_img {
                width: 25%;
                padding: 10px;
            }
            img {
                width: 100%;
                height: auto;
                vertical-align: bottom;
            }
            .nav_inner {
                width: 84%;
                padding: 10px 0;
            }
            .summary {
                width: 70%;
                padding: 10px;
            }
            .icon, .induct {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 8%;
                padding: 10px ;
                svg {
                    font-size: 1.6rem;
                }
            }
        }
        li:not(:first-child) {
            border-top: 1px solid #ecc;
        }
    }
    &_flex_list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        > li {
            border-right: 1px solid #e8d3c2;
            flex-grow: 2;
            &:not(:first-child):not(:nth-child(2)) {
                border-top: 1px solid #e8d3c2;
            }
            &:nth-child(2n), &:last-child {
                border-right: none;
            }
        }
        &.col_2 {
            > li {
                width: 50%;
                flex-grow: 2;
            }
        }
    }
    &_outer {
        position: relative;
        overflow: hidden;
        &.ichi {
            padding-bottom: 2%;
        }
    }
    &_bg {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        width: 107%;
        height: 107%;
        transform: translate(-50%, -50%);
        &.ichi {
            opacity: 0;
            background-position: center center;
            background-size: cover;
            filter: blur(5px);
            transition: opacity .3s linear 0s;
            &.active {
                opacity: .75;
            }
        }
    }
}

// ichioshi style
.ichi {
    &_wrap {
        position: absolute;
        top: 0;
        left: -30%;
        z-index: 1;
        width: 100%;
        height: 100%;
        .swiper-wrapper {
            position: relative;
            padding: 15px 0;
            margin-left: 15px;
            align-items: baseline;
        }
        .swiper-slide {
            width: 40%;
            height: auto;
            // transition: height .6s linear 0s, width .6s linear 0s;
            // &:not(.swiper-slide-active) {
            //     width: 33.333%;
            // }
        }
    }
    &_slide {
            display: block;
        article {
            line-height: 0;
        }
        &_outer {
            position: relative;
            width: 100%;
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-top: 67%;
            }
        }
        &_inner {
            display: inline-flex;
            width: 100%;
            height: 100%;
            align-items: center;
        }
    }
    &_info {
        position: absolute;
        top: 0;
        right: -30%;
        z-index: 10;
        display: block;
        width: 55%;
        height: 100%;
        padding: 2%;
        &_outer {
            position: relative;
            height: 100%;
            padding: 5%;
            background-color: rgba(#000, .75);
        }
        &_inner {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 5%;
            color: #fff;
            transition: opacity .35s linear 0s;
            &.trans {
                display: block;
                opacity: 0;
            }
            &.active {
                display: block;
                opacity: 1;
                .ichi_info_bar {
                    width: 100%;
                }
            }
        }
        &_cont {
            height: 80%;
            overflow-y: scroll;
        }
        &_title {
            margin-bottom: 2px;
            font-size: 1.6rem;
        }
        &_bar {
            display: block;
            width: 0;
            height: 2px;
            $svg-gradient-shim-threshold: 1 !global;
            @include background-image(linear-gradient(90deg, #29E9E2, #0EAEF0, #73C0F6));
            transition: width .2s linear 0s;
        }
        &_intro {
            margin-top: 2px;
            padding-bottom: 5%;
            font-size: 1.4rem;
        }
        &_foot {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            height: 25%;
            width: 100%;
            padding: 0 5% 5%;
            text-align: center;
            &::before {
                content: '';
                display: block;
                height: 15%;
                @include background-image(linear-gradient(180deg, transparent 0%, #000 65%));
            }
            .btn {
                height: 85%;
            }
        }
    }
    &_summ {
        position: absolute;
        z-index: 10;
        top: 0;
        width: 60%;
        height: 100%;
        background-color: rgba(#000, .65);
    }
    &_thumb {
        display: flex;
        align-items: stretch;
        justify-content: center;
        &_outer {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            align-self: stretch;
            max-width: 120px;
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: .55;
                transition: opacity .35s linear 0s;
            }
            &.active::before {
                opacity: 0;
            }
        }
    }
    &_img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
    }
}


// pick style
.c_outer.pickup {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
}

.pickup {
    $this: 'pickup';
    &_outer {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 33.3333%;
        width: calc(100%/3);
        padding: 1%;
        padding-bottom: 1%;
        text-decoration: none;
        // &.active {
        //     &::after {
        //         content: '';
        //         display: block;
        //         position: absolute;
        //         width: 100%;
        //         height: 25%;
        //         background-color: rgba(#000, .45);
        //     }
        // }
    }
    &_img {
        &_wrap {
            display: inline-flex;
            align-items: center;
            line-height: 0;
            flex-grow: 2;
        }
    }
    &.mens .#{$this}_outer {
        &:nth-of-type(2n) {
            background-color: #dfeef9;
        }
    }
    &.girls .#{$this}_outer {
        &:nth-of-type(2n) {
            background-color: #F9DFF0;
        }
    }
    &.reco .#{$this}_outer {
        &:nth-of-type(2n) {
            background-color: #D4FEE3;
        }
    }
}

// footer style ///////////////////////////////////
.f {
    &_outer {
        border-top: solid 3px #ddd;
        background-color: #000;
    }
    &_nav {
        &_li {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;
            list-style: none;
        }
        &_outer {
            display: block;
            width: 33.3333%;
            width: calc(100%/3);
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            &:nth-child(3n) {
                border-right: none;
            }
            &.x3 {
                width: 100%;
                border-right: none;
            }
        }
        &_inner {
            display: block;
            width: 100%;
            padding: 5px;
            color: #fff;
            text-decoration: none;
        }
        &_symb {
            min-height: 2rem;
            text-align: center;
            font-size: 2rem;
        }
        &_ti {
            text-align: center;
            font-size: 1.2rem;
        }
    }
}

.copy {
    padding: 15px 0;
    text-align: center;
    color: #fff;
}

.btn {
    color: #fff;
    text-decoration: none;
    &_ichi {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 2%;
        border-radius: 5px;
        font-weight: bold;
        $svg-gradient-shim-threshold: 1 !global;
        @include background-image(linear-gradient(135deg, #29E9E2, #0EAEF0, #73C0F6));
    }
    &_wrap {
        display: inline-flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding: 2%;
    }
    &_aside {
        display: inline-flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding: 4% 2%;
        &.mens {
            background-color: #dfeef9;
        }
        &.girls {
            background-color: #F9DFF0;
        }
        &.reco {
            background-color: #D4FEE3;
        }
    }
    &_join, &_mypage {
        display: inline-block;
        width: 100%;
        height: 0;
        padding-top: 25%;
        border-radius: 15px;
        background-size: cover;
        font-size: 0;
        line-height: 0;
    }
    &_join {
        @include text-hide(image-url('btn_join.jpg'));
    }
    &_mypage {
        @include text-hide(image-url('btn_mypage.jpg'));
    }
    &_jump {
        display: block;
        width: 100%;
        margin: 2% auto 0;
        padding: 4% 2%;
        border-radius: 5px;
        font-weight: bold;
        font-size: 1.4rem;
        $svg-gradient-shim-threshold: 1 !global;
        text-align: center;
        svg {
            margin: 0 .25em;
            vertical-align: middle;
        }
    }
    &_top {
        display: inline-block;
        padding: 3% 6%;
        border-radius: 5px;
        font-weight: bold;
        font-size: 1.4rem;
        $svg-gradient-shim-threshold: 1 !global;
        text-align: center;
    }
    &.mens {
        @include background-image(linear-gradient(135deg, #29E9E2, #0EAEF0, #73C0F6));
    }
    &.girls {
        @include background-image(linear-gradient(135deg, #FF759C, #fd4a5f, #ff947b));
    }
    &.reco {
        @include background-image(linear-gradient(135deg, #6bf17b, #43d29b, #21d4e6));
    }
}

%cf {
    overflow: hidden;
}